import { Config, User } from '@/types';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { callWhoAmIWithRetry } from './functions/utils';
import { ServiceDisruption } from './modules/core/app/service-disrupted';
import { LazyApplication } from './modules/core/app/lazy-application';
import { createInitServices } from './modules/core/init-services';
import { AuthenticationError, PermissionDeniedError } from './modules/core/api/error';

import './index.css';

function renderApplication(user: User, config: Config) {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <LazyApplication user={user} config={config} />
  )
}

async function startApp() {
  const config: Config = await getConfig();
  const services = createInitServices({ backendUrl: '/' });
  const userOrError = await callWhoAmIWithRetry(services, 0, 3, 1000);

  if (userOrError instanceof Error) {
    if (userOrError instanceof AuthenticationError || userOrError instanceof PermissionDeniedError) {
      const returnUrl = generateReturnUrl();
      window.location.href = `/login?url=${returnUrl}`;
      return;
    }

    return ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <ServiceDisruption />
    );
  }

  return renderApplication(userOrError, config);
}

// TODO: Move this to the auth service that doesn't exist yet
function generateReturnUrl() {
  const currentURL = new URL(window.location.href);
  // Need to be careful with the return URL, can get into a redirect loop if this hits on `/login`
  if (currentURL.pathname === '/login') {
    return '';
  }
  return encodeURIComponent(currentURL.toString());
}

async function getConfig() {
  return await fetch('/config', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(res => res.json());
}

(async () => {
  if (import.meta.env.VITE_APP_MOCKED === 'true') {
    console.log('Loading MSW...');
    void import('./mocks/browser')
      .then(({ worker }) => worker.start())
      .then(startApp);
  } else {
    void startApp();
  }
})();
