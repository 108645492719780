import { AskBlueJApi, IAskBlueJApi } from './api/ask-bluej-api';

type ServicesConfig = {
  backendUrl: string;
}

export type InitServices = {
  askBlueJApi: IAskBlueJApi;
}

export function createInitServices(servicesConfig: ServicesConfig): InitServices {
  const askBlueJApi = new AskBlueJApi(servicesConfig.backendUrl);
  return {
    askBlueJApi
  }
}
