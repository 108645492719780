import { stringify } from 'qs';
import { APIError } from './error';

export type Fetch = (url: RequestInfo, init?: RequestInit) => Promise<Response>;

export const AUTH_HEADERS = {
  'X-Ask-Blue-J-Request': 'true'
};

export type Methods = 'POST' | 'GET' | 'DELETE' | 'PUT';

export type Headers = Record<string, string | null | undefined>;

export type RouteGeneratorFn<TParams = never> = (params: TParams) => string;

export class ResponseError extends Error {
  public status?: number;

  constructor(message: string, status?: number) {
    super(message);
    this.status = status;
  }
}

export abstract class Api {
  private fetch: Fetch;

  constructor(protected readonly backendUrl = '', overriddenFetch?: Fetch | undefined) {
    this.fetch = overriddenFetch ?? fetch;
  }

  protected baseHeaders(): Record<string, string> {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  }

  protected async apiFetch(url: string, requestParams: RequestInit): Promise<APIResponseProps> {
    const response = await this.fetch.call(window, `${this.backendUrl}${url}`, {
      ...requestParams,
      credentials: 'include',
      headers: {
        ...this.baseHeaders(),
        ...AUTH_HEADERS,
        ...(requestParams.headers || {})
      }
    });

    const responseHeaders = createResponseHeaders(response.headers);

    if (!response.ok) {
      const errText = await response.text().catch((e) => castToError(e).message);
      const errJSON = safeJSON(errText);
      const errMessage = errJSON ? undefined : errText;
      throw this.createStatusError(response.status, errJSON, errMessage, responseHeaders);
    }

    return {
      response
    }
  }

  protected createFetch<TPayload, TResponse, TParams>(apiSubRoute: string | (RouteGeneratorFn<TParams>), method: Methods = 'POST') {
    return (payload?: TPayload, params?: TParams): APIResponse<TResponse> => {
      const requestParams: RequestInit = {
        method
      };

      let url = createUrl(apiSubRoute, params);

      if (payload) {
        if (method === 'POST' || method === 'PUT') {
          requestParams.body = JSON.stringify(payload);
        } else if (method === 'GET') {
          url = `${url}${stringify(payload, { addQueryPrefix: true })}`;
        }
      }

      return new APIResponse<TResponse>(this.apiFetch(url, requestParams));
    };
  }

  protected createStatusError(
    status: number | undefined,
    error: unknown | undefined,
    message: string | undefined,
    headers: Headers | undefined
  ) {
    return APIError.create(status, error, message, headers);
  }
}

export type APIResponseProps = {
  response: Response;
}

export class APIResponse<T> {
  private _parsedResponse: Promise<T> | undefined;
  private _responsePromise: Promise<APIResponseProps>;

  constructor(
    responsePromise: Promise<APIResponseProps>,
  ) {
    this._responsePromise = responsePromise
  }

  async parsedResponse(): Promise<T> {
    if (!this._parsedResponse) {
      this._parsedResponse = this._responsePromise.then(this.parseResponse<T>);
    }
    return this._parsedResponse;
  }

  private async parseResponse<T>(props: APIResponseProps): Promise<T> {
    const { response } = props;
    return await response.json();
  }
}

function createUrl<RouteParams>(apiSubRoute: string | (RouteGeneratorFn<RouteParams>), payload?: RouteParams) {
  let url;

  if (typeof apiSubRoute === 'string') {
    url = apiSubRoute
  } else if (typeof apiSubRoute === 'function' && payload) {
    url = apiSubRoute(payload);
  }

  if (!url) {
    throw new Error('Unable to make request, URL not provided or unable to generate');
  }

  return url;
}

export const createResponseHeaders = (
  headers: Awaited<ReturnType<Fetch>>['headers'],
): Record<string, string> => {
  return new Proxy(
    Object.fromEntries(
      headers.entries(),
    ),
    {
      get(target, name) {
        const key = name.toString();
        return target[key.toLowerCase()] || target[key];
      },
    },
  );
};

export const safeJSON = (text: string) => {
  try {
    return JSON.parse(text);
  } catch (err) {
    return undefined;
  }
};

export const castToError = (err: unknown): Error => {
  if (err instanceof Error) return err;
  if (typeof err === 'object' && err !== null) {
    try {
      return new Error(JSON.stringify(err));
    } catch { /* empty */ }
  }
  return new Error('unknown');
};

