import type { User } from '@/types';

import { InitServices } from '../modules/core/init-services';
import { AuthenticationError, PermissionDeniedError } from '../modules/core/api/error';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function callWhoAmIWithRetry(services: InitServices, attempt: number, maxAttempts: number, delay: number): Promise<User | Error> {
  try {
    return await services.askBlueJApi.whoami();
  } catch (error) {
    if (error instanceof AuthenticationError || error instanceof PermissionDeniedError) {
      return error;
    }
    if (attempt >= maxAttempts) {
      return Error('whoami retry exceeded');
    }
    await sleep(2 ** attempt * delay);
    return callWhoAmIWithRetry(services, attempt + 1, maxAttempts, delay);
  }
}
