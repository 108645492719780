import { castToError, Headers } from './api';

export class BlueJError extends Error {
}

type ErrorLike = { message: unknown } | string | undefined;

export class APIError<
  TStatus extends number | undefined = number | undefined,
  THeaders extends Headers | undefined = Headers | undefined,
  TError extends ErrorLike = ErrorLike
> extends BlueJError {
  readonly status: TStatus;
  readonly headers: THeaders;
  readonly error: TError;

  constructor(status: TStatus, error: TError, message: string | undefined, headers: THeaders) {
    super(`${APIError.createMessage(status, error, message)}`);

    this.status = status;
    this.headers = headers;
    this.error = error;
  }

  private static createMessage(status: number | undefined, error: ErrorLike, message: string | undefined) {
    const msg = constructMessageFromError(error, message)

    if (status && msg) {
      return `${status} ${msg}`;
    }

    if (status) {
      return `${status} status code (no body)`;
    }

    if (msg) {
      return msg;
    }

    return '(no status code or body)';
  }

  static create(
    status: number | undefined,
    errorResponse: unknown | undefined,
    message: string | undefined,
    headers: Headers | undefined
  ) {
    if (!status || !headers) {
      return new APIConnectionError({ message, cause: castToError(errorResponse) });
    }

    const error: ErrorLike = extractError(errorResponse);

    if (status === 400) {
      return new BadRequestError(status, error, message, headers);
    }

    if (status === 401) {
      return new AuthenticationError(status, error, message, headers);
    }

    if (status === 403) {
      return new PermissionDeniedError(status, error, message, headers);
    }

    if (status === 404) {
      return new NotFoundError(status, error, message, headers);
    }

    if (status === 409) {
      return new ConflictError(status, error, message, headers);
    }

    if (status === 422) {
      return new UnprocessableEntityError(status, error, message, headers);
    }

    if (status === 429) {
      return new RateLimitError(status, error, message, headers);
    }

    if (status >= 500) {
      return new InternalServerError(status, error, message, headers);
    }

    return new APIError(status, error, message, headers);
  }
}

export class APIConnectionError extends APIError<undefined, undefined, undefined> {
  constructor({ message, cause }: { message?: string | undefined; cause?: Error | undefined }) {
    super(undefined, undefined, message || 'Connection error.', undefined);
    if (cause) this.cause = cause;
  }
}

export class BadRequestError extends APIError<400, Headers> {}

export class AuthenticationError extends APIError<401, Headers> {}

export class PermissionDeniedError extends APIError<403, Headers> {
  isRevoked() {
    return this.error === 'SessionRevoked'
  }
}

export class NotFoundError extends APIError<404, Headers> {}

export class ConflictError extends APIError<409, Headers> {}

export class UnprocessableEntityError extends APIError<422, Headers> {}

export class RateLimitError extends APIError<429, Headers> {}

export class InternalServerError extends APIError<number, Headers> {}

function constructMessageFromError(error: unknown, fallback: string | undefined): string | undefined {
  if (error) {
    if (typeof error === 'string') {
      return error;
    }

    if (typeof error === 'object' && 'message' in error) {
      if (typeof error.message === 'string') {
        return error.message
      }
      return JSON.stringify(error.message);
    }
  }

  return fallback;
}

function extractError(errResponse: unknown): ErrorLike {
  if (errResponse) {
    if (typeof errResponse === 'object' && 'error' in errResponse) {
      if (typeof errResponse.error === 'string') {
        return errResponse.error;
      }
    }
  }
}
