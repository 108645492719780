import React, { ReactElement } from 'react';
import Logo from '../../../assets/svg/logo.svg?react'

type ErrorPageProps = {
  title: string;
  description: string | ReactElement;
};

export function ErrorPage({ title, description }: ErrorPageProps) {
  return (
    <div className="bg-white min-h-screen px-8 sm:px-32 lg:px-72 py-20 sm:py-24">
      <div className="flex justify-center">
        <Logo className="w-48 md:w-60 h-auto"/>
      </div>
      <h1 className="mt-8 md:mt-16 text-3xl sm:text-5xl">
        {title}
      </h1>
      <div className="mt-6 text-base">
        {description}
      </div>
      <div className="mt-6">
        <a
          href="https://support.bluej.com"
          target="_blank"
          title="Contact Support"
          className="bg-blue-400 hover:bg-blue-500 text-white text-sm p-4 rounded uppercase"
        >
          Contact Support
        </a>
      </div>
    </div>
  )
}
